import React from 'react';

interface Props {
  className?: string;
}

export const RedditArrow = ({ className }: Props) => (
  <svg
    version="1.0"
    viewBox="0 0 1280 1280"
    className={className}
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0,1280) scale(0.100000,-0.100000)" stroke="none">
      <path
        d="M6245 12778 c-200 -44 -333 -117 -494 -272 -53 -51 -1149 -1102
  -2436 -2336 -3028 -2904 -3071 -2945 -3122 -3010 -133 -170 -219 -465 -183
  -629 35 -159 45 -192 80 -267 50 -109 104 -185 187 -265 83 -81 140 -119 246
  -169 175 -81 33 -74 1677 -77 1359 -3 1466 -4 1478 -20 9 -13 12 -527 12
  -2487 0 -2658 -2 -2524 50 -2663 84 -226 259 -410 479 -503 40 -17 123 -42
  184 -56 l112 -24 1905 3 c1897 2 1905 2 1995 23 173 41 319 120 439 238 104
  102 171 212 222 366 l29 85 5 2507 c3 1701 8 2510 15 2517 7 7 487 12 1475 13
  1644 4 1502 -3 1677 78 104 48 161 88 247 169 137 131 213 277 256 491 25 119
  25 151 0 270 -32 159 -75 262 -155 375 -53 75 -26 49 -3010 2910 -1353 1297
  -2506 2402 -2561 2456 -169 163 -299 234 -509 278 -119 25 -181 25 -300 -1z"
      />
    </g>
  </svg>
);
